import React, { FC, useCallback, useState } from 'react'
import { Form, Field } from 'react-final-form'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { toast } from 'react-toastify'

import { Box, Flex, Input, Button } from 'src/components'

const SubscribeForm: FC = () => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = useCallback(async ({ email }, { reset }) => {
    setIsLoading(true)

    try {
      const res = await addToMailchimp(email)

      toast(res.msg)
    } catch (error) {
      toast(error.message)
    } finally {
      setTimeout(reset)
      setIsLoading(false)
    }
  }, [])

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <Box as="form" width="100%" px="16px" onSubmit={handleSubmit}>
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection={['column', 'row']}
          >
            <Field
              name="email"
              type="email"
              placeholder="Email Address"
              component={Input}
              maxWidth={[null, '336px']}
              width="100%"
              mr={[0, '12px']}
              mb={['12px', 0]}
            />

            <Button
              type="submit"
              disabled={isLoading}
              width={['100%', '122px']}
            >
              Notify Me
            </Button>
          </Flex>
        </Box>
      )}
    />
  )
}

export default SubscribeForm
