import React, { FC, Suspense, lazy } from 'react'

import { Layout, Seo } from 'src/components'
import { SubscribeSection } from 'src/components/Home'

const LogPageViewLazy = lazy(() => import('../components/Home/LogPageView'))

const HomePage: FC = () => {
  const isSSR = typeof window === 'undefined'

  return (
    <Layout>
      {!isSSR && (
        <Suspense fallback={<div />}>
          <LogPageViewLazy />
        </Suspense>
      )}

      <Seo />

      <SubscribeSection />
    </Layout>
  )
}

export default HomePage
