import React, { FC } from 'react'

import { Box, Flex, Text } from 'src/components'
import SubscribeForm from './SubscribeForm'

const SubscribreSection: FC = () => (
  <Box
    width="100%"
    maxWidth={['400px', '640px']}
    border="solid"
    borderRadius="l"
    py={['36px', '66px']}
    mt={['70px', '170px']}
    height="fit-content"
    mx="16px"
    boxShadow="l"
    bg="background"
  >
    <Flex flexDirection="column" alignItems="center">
      <Text fontWeight="bold" fontSize={['xlMob', 'xl']} textAlign="center">
        We’re Coming Soon
      </Text>

      <Box mt={['8px', '16px']} mb={['28px', '48px']}>
        <Flex flexDirection="column" alignItems="center">
          <Text fontSize={['lMob', 'l']} textAlign="center">
            HitchHero is launching soon!
          </Text>

          <Text fontSize={['lMob', 'l']} textAlign="center">
            Be the first to know and subscribe today.
          </Text>
        </Flex>
      </Box>

      <SubscribeForm />
    </Flex>
  </Box>
)

export default SubscribreSection
